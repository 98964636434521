<script setup></script>

<template>
  <div>
    <AppHeader />

    <main>
      <NuxtPage />
    </main>

    <AppFooter />

    <div
      v-show="false"
      class="bottom-0 h-[120px] border-t bg-white/50 w-full p-4 hidden md:block fixed backdrop-blur-lg"
    >
      <div class="bg-gray-100 w-[728px] h-[90px] mx-auto">Ad</div>
    </div>
  </div>
</template>
