<template>
  <nav class="menu" :class="{ 'menu--open': props.show }">
    <div class="menu__container">
      <ul class="menu__items">
        <li class="menu__item" v-for="link in links" :key="link.name">
          <NuxtLink :to="link.to" class="menu__link" @click.native="close">{{
            link.name
          }}</NuxtLink>
        </li>
      </ul>

      <SocialLinks class="z-20" />
    </div>
  </nav>
</template>

<script setup lang="ts">
import SocialLinks from "~/components/AppHeader/SocialLinks";

const links = [
  {
    name: "Home",
    to: "/",
  },
  {
    name: "Pregnancy",
    to: "/pregnancy",
  },
  {
    name: "Parenting",
    to: "/parenting",
  },
  {
    name: "Motherhood",
    to: "/motherhood",
  },
  {
    name: "Lifestyle",
    to: "/lifestyle",
  },
  {
    name: "Reviews",
    to: "/reviews",
  },
  {
    name: "Days Out",
    to: "/days-out",
  },
];

const props = defineProps<{
  show: Boolean;
}>();

const emit = defineEmits(["close"]);

const close = () => {
  emit("close");
};
</script>

<style>
.menu {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: -1;
  visibility: hidden;
  transform: scale(1.1);
}

.menu,
.menu__container {
  width: 100vw;
  height: 100vh;
}

.menu__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu__items {
  list-style: none;
  padding: 5vw;
}

@media only screen and (orientation: landscape) {
  .menu__items {
    -ms-flex: 1;
    flex: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.menu__item {
  display: block;
  text-align: center;
  transform: skewX(-4deg) skewY(-4deg);
}

@media only screen and (orientation: landscape) {
  .menu__item {
    -ms-flex: 33.33%;
    flex: 33.33%;
    margin: 2vw 0;
  }
}

.menu__link {
  @apply text-4xl font-heading font-bold italic;
  transition: background 200ms ease;
  line-height: 1.4;
  text-transform: none;
  background-repeat: no-repeat;
  background-image: linear-gradient(#b6ffed, #b6ffed);
  background-size: 100% 25%;
  background-position: center bottom 10%;
  background-origin: padding-box;
  position: static;

  &:hover {
    background-size: 100% 100%;
  }
}

@media only screen and (orientation: landscape) {
  .menu__link {
    font-size: 1.625rem;
    line-height: 1.4;
  }
}

.menu--open {
  z-index: 2;
  visibility: visible;
  transform: scale(1);
  transition: transform 0.3s ease;
}
</style>
