<template>
  <div>
    <PromoBar v-if="false" />

    <header class="relative z-10 border-b">
      <section class="container mx-auto flex justify-between items-center">
        <nav class="mx-auto flex-1 hidden md:block py-2">
          <NuxtLink to="/our-family" class="navItem mr-8">Our family</NuxtLink>
          <NuxtLink to="/work-with-us" class="navItem">Work with us</NuxtLink>
        </nav>

        <SocialLinks class="justify-center my-4 md:justify-end" />
      </section>

      <section
        class="fixed z-20 top-0 right-0 flex items-center md:relative md:hidden"
      >
        <div class="w-full text-right px-4 flex">
          <div class="w-1/2"></div>
          <div class="w-1/2 text-right flex justify-end items-center pt-4">
            <ToggleBar :is-open="showMenu" @click="toggleMenu" />
          </div>
        </div>
      </section>

      <div class="md:flex container justify-between items-center mx-auto py-8">
        <div class="flex-1 text-center relative z-10">
          <NuxtLink aria-label="Leelee Loves" to="/" class="inline-block">
            <Logo width="200" alt="Leelee Loves" />
          </NuxtLink>
        </div>

        <div
          v-if="false"
          class="flex-1 items-center text-right hidden md:block"
        >
          <Button to="/subscribe">Subscribe</Button>
        </div>
      </div>
    </header>

    <nav class="hidden md:flex border-b justify-center py-2">
      <ul class="mx-auto flex items-center space-x-8">
        <li>
          <NuxtLink to="/parenting" class="navItem">Parenting</NuxtLink>
        </li>
        <li>
          <NuxtLink to="/lifestyle" class="navItem">Lifestyle</NuxtLink>
        </li>
        <li>
          <NuxtLink to="/pregnancy" class="navItem">Pregnancy</NuxtLink>
        </li>
        <li v-if="false">
          <NuxtLink to="/motherhood" class="navItem">Motherhood</NuxtLink>
        </li>
        <li>
          <NuxtLink to="/reviews" class="navItem">Reviews</NuxtLink>
        </li>
        <li>
          <NuxtLink to="/days-out" class="navItem">Days Out</NuxtLink>
        </li>
      </ul>
    </nav>

    <MobileNav :show="showMenu" class="md:hidden" @close="toggleMenu" />
  </div>
</template>

<script setup lang="ts">
import Logo from "~/assets/images/leeleeloves.svg";
import PromoBar from "~/components/AppHeader/PromoBar";
import ToggleBar from "~/components/AppHeader/ToggleBar";
import SocialLinks from "~/components/AppHeader/SocialLinks.vue";

let showMenu = ref(false);
let showBlogMenu = ref(false);

const toggleMenu = () => {
  showMenu.value = !showMenu.value;
};
</script>

<style>
.navItem {
  @apply text-sm font-bold uppercase py-1 relative;

  &:hover {
    @apply underline underline-offset-2;
  }
}
</style>
